var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Payment Schedule")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-select',{attrs:{"label":"Filter by Year","items":_vm.filter.years,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.selected),callback:function ($$v) {_vm.$set(_vm.filter, "selected", $$v)},expression:"filter.selected"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"hide-default-footer":"","disable-pagination":"","items":_vm.payments,"no-data-text":"No payments have been found"},scopedSlots:_vm._u([{key:"item.totalPrice",fn:function({ item }){return [_vm._v(" £"+_vm._s(_vm.formatPrice(item.totalPrice))+" ")]}},{key:"item.deposit",fn:function({ item }){return [_vm._v(" £"+_vm._s(_vm.formatPrice(item.deposit))+" ")]}},{key:"item.totalPayments",fn:function({ item }){return [_vm._v(" £"+_vm._s(_vm.formatPrice(item.totalPayments))+" ")]}},{key:"item.outstanding",fn:function({ item }){return [_vm._v(" £"+_vm._s(_vm.formatPrice(item.outstanding))+" ")]}},{key:"item.totalCosts",fn:function({ item }){return [_vm._v(" £"+_vm._s(_vm.formatPrice(item.totalCosts))+" ")]}},{key:"item.profit",fn:function({ item }){return [_vm._v(" £"+_vm._s(_vm.formatPrice(item.profit))+" ")]}},{key:"item.startDate",fn:function({ item }){return [(item.startDate)?_c('span',[_vm._v(_vm._s(item.startDate))]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.endDate",fn:function({ item }){return [(item.endDate)?_c('span',[_vm._v(_vm._s(item.endDate))]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.customer",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-drum-customers-profile',
            params: { customerId: item.lead_customer.id },
          }}},[_vm._v(_vm._s(item.lead_customer.full_name))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-drum-proposals-itinerary',
                params: {
                  tourId: item.id,
                  itineraryId: item.itineraries[0].id,
                },
              }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th',[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.total.totalPrice)))]),_c('th',[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.total.deposit)))]),_c('th',[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.total.totalPayments)))]),_c('th',[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.total.outstanding)))]),_c('th',[_vm._v("£"+_vm._s(_vm.formatPrice(_vm.total.totalCosts)))]),(
              this.user.roles[0].name == 'VH Superadmin' ||
              this.user.roles[0].name == 'Manager'
            )?_c('th',[_vm._v(" £"+_vm._s(_vm.formatPrice(_vm.total.profit))+" ")]):_vm._e(),_c('th')])])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }