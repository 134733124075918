<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Payment Schedule</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-row align="center" justify="end" no-gutters>
            <v-select
              label="Filter by Year"
              v-model="filter.selected"
              :items="filter.years"
              outlined
              dense
              hide-details
            ></v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        hide-default-footer
        disable-pagination
        :items="payments"
        no-data-text="No payments have been found"
      >
        <template slot="body.append">
          <tr>
            <th>Total</th>
            <th></th>
            <th></th>
            <th>£{{ formatPrice(total.totalPrice) }}</th>
            <th>£{{ formatPrice(total.deposit) }}</th>
            <th>£{{ formatPrice(total.totalPayments) }}</th>
            <th>£{{ formatPrice(total.outstanding) }}</th>
            <th>£{{ formatPrice(total.totalCosts) }}</th>
            <th
              v-if="
                this.user.roles[0].name == 'VH Superadmin' ||
                this.user.roles[0].name == 'Manager'
              "
            >
              £{{ formatPrice(total.profit) }}
            </th>
            <th></th>
          </tr>
        </template>

        <template v-slot:item.totalPrice="{ item }">
          £{{ formatPrice(item.totalPrice) }}
        </template>

        <template v-slot:item.deposit="{ item }">
          £{{ formatPrice(item.deposit) }}
        </template>

        <template v-slot:item.totalPayments="{ item }">
          £{{ formatPrice(item.totalPayments) }}
        </template>

        <template v-slot:item.outstanding="{ item }">
          £{{ formatPrice(item.outstanding) }}
        </template>

        <template v-slot:item.totalCosts="{ item }">
          £{{ formatPrice(item.totalCosts) }}
        </template>

        <template v-slot:item.profit="{ item }">
          £{{ formatPrice(item.profit) }}
        </template>

        <template v-slot:item.startDate="{ item }">
          <span v-if="item.startDate">{{ item.startDate }}</span>
          <span v-else class="text--disabled">N/A</span>
        </template>
        <template v-slot:item.endDate="{ item }">
          <span v-if="item.endDate">{{ item.endDate }}</span>
          <span v-else class="text--disabled">N/A</span>
        </template>
        <template v-slot:item.customer="{ item }">
          <router-link
            :to="{
              name: 'module-drum-customers-profile',
              params: { customerId: item.lead_customer.id },
            }"
            >{{ item.lead_customer.full_name }}</router-link
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                :to="{
                  name: 'module-drum-proposals-itinerary',
                  params: {
                    tourId: item.id,
                    itineraryId: item.itineraries[0].id,
                  },
                }"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Payment Schedule",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Leader Golfer", value: "customer" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Total Price", value: "totalPrice" },
        { text: "Deposit Due", value: "deposit" },
        { text: "Total Payments Received", value: "totalPayments" },
        { text: "Outstanding", value: "outstanding" },
        { text: "Total Costs", value: "totalCosts" },
      ],
      filter: {
        selected: new Date().getFullYear(),
        years: [2021, 2022, 2023, 2024, 2025, 2026, 2027],
      },
    };
  },

  mounted() {
    if (
      this.user.roles[0].name == "VH Superadmin" ||
      this.user.roles[0].name == "Manager"
    ) {
      this.tableHeaders.push({ text: "Gross Profit", value: "profit" });
    }

    this.tableHeaders.push({
      text: "Actions",
      value: "actions",
      sortable: false,
      align: "right",
    });
  },

  computed: {
    user() {
      return this.$store.state.drum.paymentSchedule["user"];
    },

    payments() {
      let payments = this.$store.state.drum.paymentSchedule["payments"];

      return payments.filter((p) => {
        return parseInt(p.startYear) === this.filter.selected;
      });
    },

    total() {
      const sums = {
        totalPrice: 0,
        deposit: 0,
        totalPayments: 0,
        outstanding: 0,
        totalCosts: 0,
        profit: 0,
      };
      this.payments.forEach(
        ({
          totalPrice,
          deposit,
          totalPayments,
          outstanding,
          totalCosts,
          profit,
        }) => {
          sums.totalPrice += totalPrice;
          sums.deposit += deposit;
          sums.totalPayments += totalPayments;
          sums.outstanding += outstanding;
          sums.totalCosts += totalCosts;
          sums.profit += profit;
        }
      );

      return sums;
    },
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
